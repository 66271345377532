import { hoianApi } from ".";
import { PaymentParamsType } from "../hooks";

import type { Advertisement } from "./types";

export const getPayId = async ({
  articleId,
  paymentParams,
}: {
  articleId: string;
  paymentParams: PaymentParamsType;
}): Promise<{ pay_id: string }> =>
  await hoianApi
    .get(
      `/articles/${articleId}/pay_id.json?` +
        `${
          paymentParams.optionUi === "select_option"
            ? `option_id=${paymentParams.optionId}`
            : `price=${paymentParams.price}`
        }`,
    )
    .then((res) => res.data);

export const approvePayment = async ({
  articleId,
  payId,
}: {
  articleId: string;
  payId: string;
}): Promise<{
  neighborhood_advertisement: Advertisement;
}> =>
  await hoianApi
    .post(`/articles/${articleId}/advertise.json`, {
      pay_id: payId,
    })
    .then((res) => res.data);
