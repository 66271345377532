import type { Advertisement } from "../api/types";
import { textEllipsis } from "./textEllipsis";
import { calcImpressionChange } from "./calcImpressionChange";

const getDefaultText = (title: string) =>
  `🚀 "${title}"에 관심 있을 만한 이웃에게 홈, 검색, 카테고리 상단에서 이 게시글을 보여주고 있어요.`;

const getReadsText = (title: string, readsCount: number) =>
  `👀 "${title}"에 관심 있는 이웃이 이 게시글을 <span>${readsCount}회</span> 조회했어요.`;

const getWatchesText = (title: string, watchesCount: number) =>
  `🧡 "${title}"에 관심 있는 이웃 <span>${watchesCount}명</span>이 관심을 눌렀어요.`;

const getWatchesAndReadsText = (title: string, watchesCount: number, readsCount: number) =>
  `⭐️ "${title}"에 관심 있는 이웃이 <span>${readsCount}회</span> 게시글을 조회하고 <span>${watchesCount}명</span>이 관심을 눌렀어요.`;

export const getImpressions = (data: Advertisement) => {
  const changes = calcImpressionChange(data);

  const status =
    !changes.readsCount && !changes.watchesCount
      ? "default"
      : changes.readsCount && changes.watchesCount
      ? "watchesAndReads"
      : changes.watchesCount
      ? "watches"
      : changes.readsCount
      ? "reads"
      : "default";

  return {
    status,
    changes,
  };
};

export const displayPerformance = (data: Advertisement) => {
  const impressions = getImpressions(data);

  const title = textEllipsis(data.article.title);

  switch (impressions.status) {
    case "watches":
      return getWatchesText(title, impressions.changes.watchesCount);
    case "reads":
      return getReadsText(title, impressions.changes.readsCount);
    case "watchesAndReads":
      return getWatchesAndReadsText(
        title,
        impressions.changes.watchesCount,
        impressions.changes.readsCount,
      );
    default:
      return getDefaultText(title);
  }
};
