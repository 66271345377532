import { vars } from "@seed-design/design-token";
import { basicUIPlugin } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { karrotAnalyticsPlugin } from "./karrotAnalyticsPlugin";
import { stackflow } from "@stackflow/react";
import React from "react";

import ActivityNotFound from "../activities/ActivityNotFound";
import { bridge } from "../bridge";
import AdvertiseImpression from "../activities/AdvertiseImpression";
import OptionBottomSheet from "../activities/OptionBottomSheet";

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */

const theme = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
  ? "cupertino"
  : "android";

const { Stack, useFlow } = stackflow({
  transitionDuration: 270,
  activities: {
    ActivityHome: React.lazy(() => import("../activities/ActivityHome")),
    Article: React.lazy(() => import("../activities/Article")),
    Payment: React.lazy(() => import("../activities/Payment")),
    AdvertiseImpression,
    OptionBottomSheet,
    ActivityNotFound,
  },
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor: "rgba(0,0,0,0)",
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        closeButton: {
          onClick() {
            bridge.closeRouter({});
          },
        },
      },
    }),
    historySyncPlugin({
      routes: {
        ActivityHome: "/",
        Article: "/articles/:articleId",
        AdvertiseImpression: "/neighborhood_advertisements/:advertisementId/impression",
        OptionBottomSheet: "/option/:articleId",
        Payment: "/payment",
        ActivityNotFound: "/404",
      },
      fallbackActivity: () => "ActivityNotFound",
    }),
    karrotAnalyticsPlugin({
      bridge,
      serviceName: "fleamarket",
      screenClassName: (activityName) => {
        return `neighborhood_${activityName}`;
      },
    }),
  ],
});

export { Stack };
export type TypeUseFlow = typeof useFlow;
