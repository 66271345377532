import { bridge } from "../bridge";

const makeId = () => {
  return `${Date.now()}${Math.floor(Math.random() * 100)}`;
};

const EVENT_TYPES = {
  COMPLETED: "REQ.NEIGHBOR.COMPLETE_ADS",
  FAILED: "REQ.NEIGHBOR.FAILED_ADS",
};

Object.freeze(EVENT_TYPES);

type PropsType = {
  type: "COMPLETED" | "FAILED";
  payload: {
    message?: string;
    advertisementId?: string;
  };
};

/**
 * 네이티브에 커스텀 브릿지로 이벤트를 날리면, 네이티브가 동작 이후 다음 함수를 호출하라는 명령을 웹뷰에 내리고있어요.
 * 선언되어있지 않으면 없는 함수를 호출한다는 에러가 발생해서, 아무 동작 안하는 빈 함수로 선언했어요.
 * */
if (window?.native) {
  // @ts-ignore
  window.native.neighborhoodAdvertisementMessage = () => {};
  // @ts-ignore
  window.native.onNeighborhoodMessage = () => {};
}

export const nativeAdHandler = ({ type, payload }: PropsType) => {
  const myWindow = window as any;

  const EVENT_STRING = JSON.stringify({
    id: makeId(),
    type: EVENT_TYPES[type],
    payload,
  });

  const deviceOS = bridge.driver.getCurrentEnvironment();

  if (
    deviceOS === "Android" &&
    typeof myWindow.AndroidFunction?.neighborhoodAdvertisementHandler === "function"
  ) {
    myWindow.AndroidFunction?.neighborhoodAdvertisementHandler(EVENT_STRING);
  }

  if (
    deviceOS === "Cupertino" &&
    typeof myWindow.webkit?.messageHandlers?.neighborhoodAdvertisementHandler?.postMessage ===
      "function"
  ) {
    myWindow.webkit?.messageHandlers?.neighborhoodAdvertisementHandler?.postMessage(EVENT_STRING);
  }
};
