import { useReadyPayment } from "./useReadyPayment";
import { useApprovePayment } from "./useApprovePayment";
import { useErrorSnackbar } from "./useSnackbarError";
import { nativeAdHandler } from "../lib/nativeAdHandler";

export type PaymentParamsType =
  | {
      optionUi: "select_option";
      optionId: number;
    }
  | {
      optionUi: "slider";
      price: number;
    };

type PaymentType = {
  articleId: string;
  isFromNativeApp: boolean;
  paymentParams: PaymentParamsType;
};

export const usePayment = ({ articleId, isFromNativeApp, paymentParams }: PaymentType) => {
  const { openErrorSnackbar } = useErrorSnackbar();

  const makeOnError = () => {
    return (error: any) => {
      const errorMessage = error?.message || String(error);

      if (isFromNativeApp) {
        // 네이티브 이웃광고에서 결제 페이지를 접근했을 때는. 스낵바에 띄울 메세지를 네이티브 이벤트로 보내줘요.
        nativeAdHandler({ type: "FAILED", payload: { message: errorMessage } });
      } else {
        openErrorSnackbar({ message: errorMessage });
      }
    };
  };

  // 결제 PAY ID 생성 + 페이웹뷰 띄우기
  const {
    data: readyPaymentData,
    refetch: fetchGetPayIdAndOpenPayWebview,
    isFetching: isFetchingReadyPayment,
  } = useReadyPayment({
    articleId,
    isFromNativeApp,
    paymentParams,
    onError: makeOnError(),
  });

  const payId = readyPaymentData?.payId;

  // 결제완료 API
  const { isFetching: isFetchingApprovePayment } = useApprovePayment({
    articleId,
    payId,
    isFetchEnabled: readyPaymentData?.isReadyToApprovePayment || false,
    isFromNativeApp,
    onError: makeOnError(),
  });

  const payAdvertise = () => {
    fetchGetPayIdAndOpenPayWebview();
  };

  return { payAdvertise, isFetching: isFetchingReadyPayment || isFetchingApprovePayment };
};
