import { installKarrotBridgeDriver, makeKarrotBridge } from "@daangn/karrotbridge";
import React, { createContext, useContext } from "react";

import { APP_ENV } from "./constants";

const { driver } = installKarrotBridgeDriver();
export const bridge = makeKarrotBridge({ driver });

const BridgeContext = createContext(bridge);

export const BridgeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>;
};

export const useBridge = () => {
  return useContext(BridgeContext);
};

type Event = {
  target: "FIREBASE" | "KARROT";
  name: string;
  params: {
    [key: string]: string;
  };
};

export const logAnalyticsEvent = (
  target: Event["target"],
  name: string,
  params?: Event["params"],
): void => {
  const event: Event = { target, name, params: { ...params } };

  bridge.logEvent({
    analytics: {
      target: event.target,
      name: event.name,
      params: JSON.stringify(event.params),
    },
  });
};
