import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "@daangn/sprout-components-snackbar";

import { BridgeProvider } from "./bridge";
import { APP_ENV, IS_PROD, IS_ALPHA } from "./constants";
import { Stack } from "./stackflow";
import SkeletonThemeProvider from "./components/SkeletonTheme";
import * as Sentry from "@sentry/react";
import { RecoilRoot } from "recoil";
import { BridgeInfoProvider } from "./bridgeInfo";

if (IS_PROD || IS_ALPHA) {
  Sentry.init({
    dsn: IS_PROD
      ? "https://e7661279fd618765d7569c09693e781d@o24217.ingest.sentry.io/4505910435250176"
      : IS_ALPHA
      ? "https://514220c097327010557a4f6a3fb45ad1@o24217.ingest.sentry.io/4505910416834560"
      : "",
    tracesSampleRate: 0.0,
    ignoreErrors: [
      "Java exception was raised during method invocation", // 안드로이드 앱 인스턴스가 내려갔는데, 브릿지 호출 시 발생하는 에러로 무시처리 (유저 영향 X)
      "ResizeObserver loop limit exceeded", // 크롬 일부 버전에서만 발생하는 에러로, 무해하다고 함.
      "Request aborted", // 네트워크 불안정 문제로 파악돼요
      "'text/html' is not a valid JavaScript MIME type.", // 여러 웹뷰에서 간헐적으로 기록되는 증상인데, 크티티컬하지 않다고 판단했어요.
      "Failed to fetch dynamically imported module", // 여러 웹뷰에서 간헐적으로 기록되는 증상인데, 크리티컬하지 않다고 판단했어요. (안드 웹뷰 인스턴스 릭 관련으로 추측)
    ],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 2,
      suspense: true,
      useErrorBoundary: true,
    },
  },
});

if (APP_ENV === "alpha") {
  import("./vConsole").then((init) => init.default());
}

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BridgeProvider>
            <Suspense>
              <SkeletonThemeProvider>
                <SnackbarProvider offset="16px">
                  <BridgeInfoProvider>
                    <Stack />
                  </BridgeInfoProvider>
                </SnackbarProvider>
              </SkeletonThemeProvider>
            </Suspense>
          </BridgeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  );
};

export default App;
