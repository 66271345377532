import Skeleton from "react-loading-skeleton";

import * as css from "./Impression.css";
import * as skeletonCss from "./Skeleton.css";

const ImpressionSkeleton = () => {
  return (
    <section className={css.impressionSection}>
      <div className={css.statusBox}>
        <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "550px" }}>
          <Skeleton width="35%" height={20} />
          <Skeleton width="55%" height={14} />
        </div>
      </div>
      <div className={css.reactionBox}>
        <div className={css.title}>
          <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "300px" }}>
            <Skeleton width="35%" height={16} />
          </div>
        </div>
        <div className={css.reactionImpressionWrapper}>
          <div className={css.reactionListBox}>
            <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "300px" }}>
              <Skeleton width="80%" height={16} />
              <Skeleton width="50%" height={16} />
              <Skeleton width="65%" height={16} />
            </div>
          </div>
        </div>
        <div className={css.noticeBox}>
          <div className={css.noticeDescription}>
            <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "340px" }}>
              <Skeleton width="85%" height={16} />
              <Skeleton width="70%" height={16} />
            </div>
          </div>
        </div>
      </div>
      <div className={css.moreReactionBox}>
        <div className={css.moreReactionTitleBox}>
          <div className={css.moreReactionTitle}>
            <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "300px" }}>
              <Skeleton width="50%" height={16} />
            </div>
          </div>
        </div>
        <ul className={css.list}>
          {Array.from({ length: 2 }, (_v, k) => (
            <li key={k} className={css.listItem}>
              <div
                className={skeletonCss.itemSkeletonBox}
                style={{ alignItems: "baseline", width: "fit-content" }}
              >
                <Skeleton circle width={26} height={26} />
              </div>
              <div className={skeletonCss.textSkeletonBox} style={{ maxWidth: "450px" }}>
                <Skeleton width="70%" height={17} />
                <Skeleton width="85%" height={15} />
                <Skeleton width="40%" height={15} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ImpressionSkeleton;
