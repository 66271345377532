import * as React from "react";
import { Checkbox } from "@daangn/sprout-components-checkbox";

import * as css from "./Payment.css";
import { useApprovePayment, useReadyPayment } from "../hooks";
import { logAnalyticsEvent } from "../bridge";
import LoadingSpinner from "./LoadingSpinner";
import { useActivityParams } from "@stackflow/react";
import { usePayment } from "../hooks/usePayment";

type PaymentProps = {
  articleId: string;
  price: number;
  selectedOptionId: number;
};
const Payment: React.FC<PaymentProps> = ({ articleId, price, selectedOptionId }) => {
  const { from } = useActivityParams();
  const { payAdvertise, isFetching: isFetchingPayment } = usePayment({
    articleId,
    isFromNativeApp: false,
    paymentParams: {
      optionUi: "select_option",
      optionId: selectedOptionId,
    },
  });

  const onAdStartClick = async () => {
    // 과거 로깅
    from === "landing_page"
      ? logAnalyticsEvent("FIREBASE", "click_neighbor_ads_payment_button")
      : from === "retry_page"
      ? logAnalyticsEvent("FIREBASE", "click_neighbor_ads_repayment_button")
      : "";

    // 최신 로깅
    logAnalyticsEvent(
      "KARROT",
      "client_clicked_fleamarket_neighborhoodAdvertisement_optionSelectionBottomSheet_paymentButton_v1",
      {
        article_id: articleId,
        previous_page: from,
        option_price: String(price),
        option_view_type: "select_option",
        neighborhood_advertisement_option_id: String(selectedOptionId),
      },
    );

    payAdvertise();
  };

  return (
    <>
      <section className={css.bottomContentWrapper}>
        <div className={css.payButtonBox}>
          <button className={css.payButton} onClick={onAdStartClick}>
            홍보 시작하기
          </button>
        </div>
      </section>
      {isFetchingPayment && <LoadingSpinner />}
    </>
  );
};

export default Payment;
