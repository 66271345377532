/**
 * 결제 인증 실패 에러 코드
 */
export declare enum PayErrorCode {
  /**
   * 데이터 정합성 오류가 발생했어요
   */
  DATA_INTEGRITY_VIOLATED = "PAM0300",
  /**
   * 잘못된 요청이에요
   */
  ILLEGAL_ARGUMENT = "PAM0400",
  /**
   * 권한 검증에 실패했어요
   */
  UNAUTHORIZED = "PAM0401",
  /**
   * 요청한 정보를 찾을 수 없어요
   */
  NOT_FOUND = "PAM0404",
  /**
   * 중복된 요청이에요
   */
  DUPLICATED_REQUEST = "PAM0409",
  /**
   * 만료된 자원에 대한 요청이에요
   */
  EXPIRED = "PAM0410",
  /**
   * 서버 내부 오류가 발생했어요
   */
  INTERNAL_SERVER_ERROR = "PAM0500",
  /**
   * PG사 연동 오류가 발생했어요
   */
  PG_ERROR = "PAM0600",
  /**
   * 기관 점검중이에요
   */
  PG_MAINTENANCE = "PAM0610",
  /**
   * 카드사 점검중이에요
   */
  CARD_CORP_MAINTENANCE = "PAM0611",
  /**
   * 은행 점검중이에요
   */
  BANK_MAINTENANCE = "PAM0612",
  /**
   * 결제에 실패했어요
   */
  APPROVE_FAIL = "PAM0700",
  /**
   * 이미 결제가 진행중이에요
   */
  APPROVE_IN_PROGRESS = "PAM0701",
  /**
   * 이미 정상처리 된 결제 건이에요
   */
  ALREADY_APPROVED = "PAM0702",
  /**
   * 사용 한도를 초과했어요
   */
  LIMIT_EXCEEDED = "PAM0703",
  /**
   * 카드 잔액이 부족해요
   */
  LACK_OF_BALANCE = "PAM0704",
  /**
   * 사용이 정지된 카드에요
   */
  SUSPENDED_CARD = "PAM0705",
  /**
   * 분실 신고된 카드에요
   */
  LOST_CARD = "PAM0706",
  /**
   * 카드 등록에 실패했어요
   */
  CARD_REGISTER_FAIL = "PAM1200",
  /**
   * 카드번호를 다시 입력해주세요
   */
  INVALID_CARD_NUMBER = "PAM1201",
  /**
   * 카드 유효기간을 다시 확인해주세요
   */
  INVALID_CARD_EXPIRATION = "PAM1202",
  /**
   * 주민등록번호 혹은 사업자번호를 다시 입력해주세요
   */
  INVALID_CARD_IDENTITY_NUMBER = "PAM1205",
  /**
   * 비밀번호를 다시 입력해주세요
   */
  INVALID_CARD_PASSWORD = "PAM1206",
  /**
   * 카드유형(개인,법인,해외)를 다시 입력해주세요
   */
  INVALID_CARD_OWNER_TYPE = "PAM1207",
  /**
   * 지원하지 않는 카드에요. 다른 카드를 등록해주세요
   */
  NOT_SUPPORTED_CARD = "PAM1208",
  /**
   * 카드 삭제에 실패했어요
   */
  CARD_DELETE_FAIL = "PAM1209",
  /**
   * 가상계좌 발급에 실패했어요
   */
  VBA_ISSUE_FAIL = "PAM1300",
}

export class PayWebviewError extends Error {
  code?: PayErrorCode;
  constructor(message: string, code?: PayErrorCode) {
    super(message);
    this.code = code;
  }
}
