import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

import { bridge } from "./bridge";
import { IS_DEV } from "./constants";
import { CountryCodeType } from "./@types/env";

type BridgeInfoType = {
  AUTH_TOKEN: string | null;
  ACCEPT_LANGUAGE: string | null;
  COUNTRY_CODE: CountryCodeType | null;
  DEVICE_IDENTITY: string | null;
  REGION_ID: number | null;
  USER_AGENT: string | null;
  USER_ID: number | null;
  APP_VERSION_CODE: string | null;
};

export const BRIDGE_INFO: BridgeInfoType = {
  AUTH_TOKEN: null,
  ACCEPT_LANGUAGE: null,
  COUNTRY_CODE: null,
  DEVICE_IDENTITY: null,
  REGION_ID: null,
  USER_AGENT: null,
  USER_ID: null,
  APP_VERSION_CODE: null,
};

const useBridgeInfoInit = () => {
  const [isBridgeInfoInit, setIsBridgeInfoInit] = useState<boolean>(false);

  useEffect(() => {
    if (IS_DEV) {
      /* 로컬 개발 환경 */
      Object.assign(BRIDGE_INFO, {
        AUTH_TOKEN: import.meta.env.VITE_AUTH_TOKEN,
        ACCEPT_LANGUAGE: import.meta.env.VITE_ACCEPT_LANGUAGE,
        COUNTRY_CODE: import.meta.env.VITE_COUNTRY_CODE,
        DEVICE_IDENTITY: import.meta.env.VITE_DEVICE_IDENTITY,
        REGION_ID: import.meta.env.VITE_REGION_ID,
        USER_AGENT: import.meta.env.VITE_USER_AGENT,
        USER_ID: import.meta.env.VITE_USER_ID,
        APP_VERSION_CODE: import.meta.env.VITE_APP_VERSION_CODE,
      });
      Object.freeze(BRIDGE_INFO);
      setIsBridgeInfoInit(true);
    } else {
      /* 인앱 웹뷰 환경 */
      (async () => {
        const [appRes, userRes, regionRes] = await Promise.all([
          bridge.getAppInfo({}),
          bridge.getUserInfo({}),
          bridge.getRegionInfo({}),
        ]);

        if (!appRes || !userRes || !regionRes) {
          const errorMessage = JSON.stringify({
            appRes,
            userRes,
            regionRes,
          });

          Sentry.captureException(errorMessage);

          throw new Error(`bridge.getInfo is not working : ${errorMessage}`);
        }

        Object.assign(BRIDGE_INFO, {
          AUTH_TOKEN: userRes.info.user.authToken,
          ACCEPT_LANGUAGE: appRes.info.app.locale,
          COUNTRY_CODE: appRes.info.app.country,
          DEVICE_IDENTITY: appRes.info.app.deviceIdentity,
          REGION_ID: regionRes.info.region.id,
          USER_AGENT: appRes.info.app.userAgent,
          USER_ID: userRes.info.user.id,
          APP_VERSION_CODE: appRes.info.app.appVersionCode,
        });
        Object.freeze(BRIDGE_INFO);
        setIsBridgeInfoInit(true);
      })();
    }
  }, []);

  return { isBridgeInfoInit };
};

interface BridgeInfoProviderProps {
  children: React.ReactNode;
}

export const BridgeInfoProvider: React.FC<BridgeInfoProviderProps> = ({ children }) => {
  const { isBridgeInfoInit } = useBridgeInfoInit();

  return <>{isBridgeInfoInit && children}</>;
};
