import { SkeletonTheme } from "react-loading-skeleton";
import { vars } from "@seed-design/design-token";

import { usePrefersColorScheme } from "../hooks";

type SkeletonThemeProviderProps = {
  children: React.ReactNode;
};

const SkeletonThemeProvider: React.FC<SkeletonThemeProviderProps> = ({ children }) => {
  const preferColorScheme = usePrefersColorScheme();

  const baseColor = vars.$scale.color.gray200;
  const highlightColor =
    preferColorScheme === "light" ? vars.$scale.color.gray100 : vars.$scale.color.gray300;

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      {children}
    </SkeletonTheme>
  );
};

export default SkeletonThemeProvider;
