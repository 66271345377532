const { VITE_APP_ENV, VITE_PARTNER_APP_SECRET } = import.meta.env;

export const APP_ENV = VITE_APP_ENV;
export const IS_PROD = APP_ENV === "production";
export const IS_ALPHA = APP_ENV === "alpha";
export const IS_DEV = !IS_PROD && !IS_ALPHA;

export const PARTNER_APP_SECRET = VITE_PARTNER_APP_SECRET;

export const HOIAN_ALPHA_HOST = "https://webapp.alpha.kr.karrotmarket.com";
export const HOIAN_PROD_HOST = "https://webapp.kr.karrotmarket.com";
