import { useQuery } from "@tanstack/react-query";
import { serverErrorHandler } from "../lib/ErrorHandler";
import type { ServerErrorResponseType } from "../lib/ErrorHandler";
import { getPayId } from "../api/payment";
import { openKarrotPayWebview } from "../lib/openKarrotPayWebview";
import { useBridge } from "../bridge";
import { PaymentParamsType } from "./usePayment";

type PropsType = {
  articleId: string;
  isFromNativeApp: boolean;
  paymentParams: PaymentParamsType;
  onError?: (error: any) => void;
};

export const useReadyPayment = ({
  articleId,
  isFromNativeApp,
  paymentParams,
  onError,
}: PropsType) => {
  const bridge = useBridge();

  return useQuery({
    queryKey: ["article", "readyPayment", articleId, JSON.stringify(paymentParams)],
    queryFn: async () => {
      const payIdRes = await getPayId({ articleId, paymentParams });
      const payId = payIdRes?.pay_id;

      if (!payId) {
        serverErrorHandler(payIdRes as unknown as ServerErrorResponseType);
      }

      const { status: karrotPayWebviewStatus } = await openKarrotPayWebview({ payId });

      const isReadyToApprovePayment = karrotPayWebviewStatus === "SUCCESS";

      if (isFromNativeApp && karrotPayWebviewStatus === "CLOSED") {
        // 네이티브 앱에서 접근한 페이 결제 웹뷰에서, 닫기로 빠져나올시 웹뷰 닫아줌
        bridge.closeRouter({});
      }

      return { payId, isReadyToApprovePayment };
    },
    onError,
    enabled: false,
    retry: false,
    suspense: false,
    useErrorBoundary: false,
    cacheTime: 0,
    staleTime: 0,
  });
};
