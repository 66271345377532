import { hoianApi } from ".";

import type { Advertisement, AdvertisementInfoArticle } from "./types";
import { OptionType } from "./types";

// 광고 정보 가져오기
export const getAdInfo = async ({
  advertisementId,
}: {
  advertisementId: string;
}): Promise<Advertisement> =>
  await hoianApi.get(`/neighborhood_advertisements/${advertisementId}`).then((res) => res.data);

// 광고할 아티클 정보 가져오기
export const getAdArticleInfo = async ({
  articleId,
}: {
  articleId: string;
}): Promise<AdvertisementInfoArticle> =>
  await hoianApi
    .get(`/neighborhood_advertisements/info?article_id=${articleId}`)
    .then((res) => res.data);

// 광고 옵션 (옵션 바텀시트)
export const getAdOptions = async ({
  articleId,
}: { articleId: number }): Promise<{
  neighborhood_advertisement_options: OptionType[];
}> =>
  await hoianApi
    .get(`/neighborhood_advertisements/options.json?article_id=${articleId}`)
    .then((res) => res.data);
