/**
 * 1. 페이 서버 에러 응답 예시
 * {
    "status": {
      "code": "internal_server_error",
      "message": {
        "code": "PGW0400",
        "message": "잘못된 요청이에요",
        "debug": "헤더에서 Market-Secret을 찾을 수 없어요"
      },
      "type": "toast"
    }
  }
* 2. 중고거래 서버 에러 응답 예시
* {
    "status": {
      "code": "internal_server_error",
      "message": "요청한 정보를 찾을 수 없어요.",
      "type": "toast"
    }
  } 
*/

export type ServerErrorResponseType = PayErrorResponseType | FleamarketErrorResponseType;

type PayErrorResponseType = {
  status: {
    code: string;
    message: {
      code: string;
      message: string;
      debug: string;
    };
  };
};

type FleamarketErrorResponseType = {
  status: {
    code: string;
    message: string;
    type: string;
  };
};

export const serverErrorHandler = (data: ServerErrorResponseType) => {
  if (isFleamarketServerError(data)) {
    throw new Error(`${data.status.message}`);
  }

  if (isPayServerError(data)) {
    throw new Error(`${data?.status?.message?.message}`);
  }
};

const isFleamarketServerError = (data: any): data is FleamarketErrorResponseType => {
  return typeof data?.status?.message === "string";
};

const isPayServerError = (data: any): data is PayErrorResponseType => {
  return typeof data?.status?.message?.message === "string";
};
