export function convertDateFormat(date: string) {
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })
    .formatToParts(new Date(date))
    .reduce((acc, part: Intl.DateTimeFormatPart) => {
      acc[part.type] = part.value;
      return acc;
    }, {} as Record<Intl.DateTimeFormatPartTypes, string>);

  return `${p.year}.${p.month}.${p.day} ${p.hour}:${p.minute}`;
}
