import * as css from "./Impression.css";

import { convertDateFormat } from "../utils/dateFormat";
import AsyncBoundary from "./AsyncBoundary";
import RejectedFallback from "./RejectedFallback";
import { ReactComponent as FallIcon } from "../assets/notification_fall.svg";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { ReactComponent as StoryArticleIcon } from "../assets/story_article.svg";
import { displayPerformance, getImpressions } from "../utils/displayPerformance";
import ImpressionSkeleton from "./ImpressionSkeleton";
import { useAdInfo } from "../hooks";
import { logAnalyticsEvent } from "../bridge";
import { useFlow } from "../stackflow";
import { useEffect } from "react";
import { useActivityParams } from "@stackflow/react";
import { useAdOptions } from "../hooks/useAdOptions";
import { useRecoilValue } from "recoil";
import { optionsResState } from "../store/apiResponseStore";

type ImpressionProps = {
  advertisementId: string;
};

const ImpressionContainer: React.FC<ImpressionProps> = ({ advertisementId }) => {
  return (
    <AsyncBoundary
      pendingFallback={<ImpressionSkeleton />}
      rejectedFallback={<RejectedFallback reset />}
    >
      <Impression advertisementId={advertisementId} />
    </AsyncBoundary>
  );
};

const Impression: React.FC<ImpressionProps> = ({ advertisementId }) => {
  /**
   * from : 총 세 개의 진입 경로 확인해서 분기 후 로깅 필요.
   * 1. undefined 일 때 = 판매내역에서 다시하기창 진입,
   * 2. "landing_page" 일 때 =  랜딩페이지에서 광고 결제 완료 후 다시하기창 진입,
   * 3. "retry_page" 일 때 = 다시하기창에서 결제 완료 후 다시하기창 재진입.
   */
  const { from, is_open_option_sheet, referrer } = useActivityParams();
  const { push } = useFlow();

  const { data } = useAdInfo({ advertisementId });

  const articleId = data?.article?.id || -1;

  // 바텀시트 애니메이션이 안먹히는 현상을 없애기 위해 미리 options api를 호출한다.
  useAdOptions({
    articleId,
    enabled: articleId > 0 && data?.can_retry === true,
  });
  const options = useRecoilValue(optionsResState);

  if (!data) {
    throw new Error("adInfo data 부재");
    return <>오류가 발생했어요. 다시 시도해주세요.</>;
  }

  useEffect(() => {
    if (articleId < 0) return;

    const EVENT_NAME =
      data?.status === "completed"
        ? "client_shown_fleamarket_neighborhoodAdvertisementPerformanceCompleted_v1"
        : "client_shown_fleamarket_neighborhoodAdvertisementPerformanceOngoing_v1";

    logAnalyticsEvent("KARROT", EVENT_NAME, {
      article_id: String(articleId),
      neighborhood_advertisement_id: String(advertisementId),
      referrer: String(referrer),
    });
  }, [articleId]);

  useEffect(() => {
    if (is_open_option_sheet === "true" && !!options && !!articleId) {
      push("OptionBottomSheet", {
        articleId: String(articleId),
        from: "retry_page",
      });
    }
  }, [options, data]);

  useEffect(() => {
    if (!data) return;
    const canRetry = data.can_retry;
    let screenClassName = `neighborhood_AdvertiseImpression_${data.status}`;

    if (from === "landing_page") {
      screenClassName += "_payment";
    } else if (from === "retry_page") {
      screenClassName += "_repayment";
    }

    if (data.status === "completed") {
      if (canRetry) {
        screenClassName += "_retry";
      } else {
        screenClassName += "_noretry";
      }
    }

    logAnalyticsEvent("KARROT", "client_shown_app_screen_v1", {
      service_name: "fleamarket",
      screen_class_name: screenClassName,
    });
  }, [data, from]);

  const onRepaymentClick = async () => {
    logAnalyticsEvent(
      "KARROT",
      "client_clicked_fleamarket_neighborhoodAdvertisementPerformanceCompleted_retryButton_v1",
      { article_id: String(articleId) },
    );

    push("OptionBottomSheet", {
      articleId: String(articleId),
      from: "retry_page",
    });
  };

  const statusText = {
    ongoing: "이웃광고 중",
    prepared: "이웃광고 준비 중",
    completed: "이웃광고 종료",
  } as const;

  const isReactionChanged = getImpressions(data).status !== "default";

  const isVisiblePerformanceText =
    data.status === "ongoing" || (data.status === "completed" && isReactionChanged);

  return (
    <>
      <section className={css.impressionSection}>
        <div className={css.statusBox}>
          <div>
            <span
              className={css.status({
                ongoing: data.status === "ongoing",
              })}
            >
              {statusText[data.status]}
            </span>
            <div className={css.date}>
              <span className={css.startDate}>{convertDateFormat(data?.started_at)}</span>
              &nbsp;~&nbsp;
              <span className={css.endDate}>
                예상 완료 {convertDateFormat(data.completed_at || data.ended_at)}
              </span>
            </div>
            {data.can_retry && (
              <div className={css.retryBox}>
                <button className={css.retryButton} onClick={onRepaymentClick}>
                  이웃광고 다시 하기
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={css.reactionBox}>
          <h3 className={css.title}>이웃 반응</h3>
          <div className={css.reactionImpressionWrapper}>
            <div className={css.reactionListBox}>
              <div className={css.reactionListWrapper}>
                <div className={css.reactionTitle}>게시글 조회 수</div>
                <div>
                  <div className={css.totalCount}>{data.reads_count}회</div>
                </div>
              </div>
            </div>
            <div className={css.reactionListBox}>
              <div className={css.reactionListWrapper}>
                <div className={css.reactionTitle}>채팅 수</div>
                <div>
                  <div className={css.totalCount}>{data.chat_rooms_count}회</div>
                </div>
              </div>
            </div>
            <div className={css.reactionListBox}>
              <div className={css.reactionListWrapper}>
                <div className={css.reactionTitle}>관심 누른 이웃</div>
                <div>
                  <div className={css.totalCount}>{data.watches_count}명</div>
                </div>
              </div>
            </div>
          </div>
          {isVisiblePerformanceText && (
            <div className={css.noticeBox}>
              <div className={css.noticeDescription}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: displayPerformance(data),
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <MoreReactionInfo />
      </section>
    </>
  );
};

const moreReactionList = [
  {
    icon: <StoryArticleIcon />,
    title: "물품을 자세하게 설명해주세요.",
    description: "크기, 색상 등 만약 내가 구매한다면 궁금할 것 같은 내용들을 추가해보세요.",
  },
  {
    icon: <SearchIcon />,
    title: "비슷한 매물을 검색하고 시세를 파악해보세요.",
    description: "빨리 판매하고 싶다면 시세보다 조금 저렴하게 가격을 책정해보세요.",
  },
  {
    icon: <FallIcon />,
    title: "관심을 누른 이웃이 있다면 가격을 낮춰보세요.",
    description: "가격을 낮추면 관심을 누른 이웃들에게 가격 하락 알림이 가요.",
  },
];

const MoreReactionInfo = () => {
  return (
    <div className={css.moreReactionBox}>
      <div className={css.moreReactionTitleBox}>
        <div className={css.moreReactionTitle}>반응을 더 늘리고 싶나요?</div>
      </div>
      <ul className={css.list}>
        {moreReactionList.map((item) => (
          <li className={css.listItem} key={item.title}>
            <div className={css.listIconBox}>{item.icon}</div>
            <div className={css.contentBox}>
              <div className={css.content}>
                <div className={css.itemTitle}>{item.title}</div>
                <p className={css.itemDescription}>{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ImpressionContainer;
