import { useSnackbarAdapter } from "@daangn/sprout-components-snackbar";

export const useErrorSnackbar = () => {
  const snackbarAdapter = useSnackbarAdapter();

  const openErrorSnackbar = ({ message }: { message: string }) => {
    snackbarAdapter.create({
      type: "default",
      timeout: 3000,
      removeDelay: 500,
      message,
    });
  };

  return { openErrorSnackbar };
};
