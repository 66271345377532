import type { makeKarrotBridge } from "@daangn/karrotbridge";
import type { Activity, Stack, StackflowActions } from "@stackflow/core";
import type { StackflowReactPlugin } from "@stackflow/react";

function getActiveActivity(stack: Stack) {
  return stack.activities.find((activity) => activity.isActive);
}

export type KarrotAnalyticsPluginOptions = {
  bridge: ReturnType<typeof makeKarrotBridge>;
  serviceName: string | ((activityName: string) => string);
  screenClassName?: (activityName: string) => string;
};

export function karrotAnalyticsPlugin(options: KarrotAnalyticsPluginOptions): StackflowReactPlugin {
  let lastActivityName = "";

  const onRoute = ({ actions }: { actions: StackflowActions }) => {
    const activity = getActiveActivity(actions.getStack()) as Activity;
    if (!activity) {
      return;
    }
    if (lastActivityName === activity.name) {
      return;
    }
    lastActivityName = activity.name;

    // Note: 광고 성과 페이지는 컴포넌트단에서 로깅
    if (activity.name === "AdvertiseImpression") {
      return;
    }

    const serviceName = options.serviceName;
    const screenClassName = options.screenClassName
      ? options.screenClassName(activity.name)
      : activity.name;

    options.bridge.logEvent({
      analytics: {
        target: "KARROT",
        name: "client_shown_app_screen_v1",
        params: JSON.stringify({
          service_name: serviceName,
          screen_class_name: screenClassName,
        }),
      },
    });
  };

  return () => ({
    key: "@daangn/stackflow-plugin-karrot-analytics",
    onInit: onRoute,
    onChanged: onRoute,
  });
}
