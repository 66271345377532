import * as React from "react";

import * as css from "./RejectedFallback.css";
import { type RejectedFallbackProps } from "./ErrorBoundary";

const RejectedFallback: React.FC<RejectedFallbackProps> = ({
  error,
  reset = false,
  resetQuery,
  description = "오류가 발생했어요. 다시 시도해주세요.",
}) => {
  return (
    <div className={css.container}>
      <div className={css.contentBox}>
        <p className={css.text}>{description}</p>
        {resetQuery && reset && (
          <div className={css.retryBox}>
            <button className={css.retryButton} onClick={() => resetQuery()}>
              다시 시도
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RejectedFallback;
