import { AppScreen } from "@stackflow/plugin-basic-ui";
import { type ActivityComponentType } from "@stackflow/react";
import React from "react";

import Impression from "../components/Impression";
import { useBridge } from "../bridge";
import * as css from "./Activity.css";

type AdvertiseImpressionParams = {
  advertisementId: string;
  from?: string;
  referrer?: string;
};

const AdvertiseImpression: ActivityComponentType<AdvertiseImpressionParams> = ({ params }) => {
  const bridge = useBridge();

  return (
    <AppScreen
      appBar={{
        backButton: {
          onClick: () => {
            /**
             * 히스토리 : 이웃광고 하기 -> 이웃광고 중 페이지로 넘어오면, 뒤로가기 버튼이 뜨는데
             * 이걸 누르면 이웃광고 하기 창으로 가져서 closeRouter 해버림
             */
            bridge.closeRouter({});
          },
        },
      }}
    >
      <main className={css.activity}>
        <Impression advertisementId={params.advertisementId} />
      </main>
    </AppScreen>
  );
};

export default AdvertiseImpression;
