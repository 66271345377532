import Payment from "./Payment";
import * as css from "./OptionSheet.css";
import { useState, useEffect, useRef } from "react";
import { OptionType } from "../api/types";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as HelpIcon } from "../assets/help.svg";
import { useFlow } from "../stackflow";
import { optionsResState } from "../store/apiResponseStore";
import { useRecoilValue } from "recoil";
import "@daangn/sprout-components-slider/index.css";
import { vars } from "@seed-design/design-token";

type Props = {
  articleId?: string;
};

const OptionSheet: React.FC<any> = ({ articleId }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { pop } = useFlow();
  const options = useRecoilValue(optionsResState);

  const [isOpenUserCountTooltip, setIsOpenUserCountTooltip] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!options) {
      return;
    }
    if (!articleId) {
      throw new Error("OptionSheet에는 article Id가 필요합니다.");
    }

    let initSelectedIndex: number | undefined;
    options.forEach((option: OptionType, i: number) => {
      if (option.recommended && !initSelectedIndex) {
        initSelectedIndex = i;
      }
    });
    setSelectedIndex(initSelectedIndex ?? 0);
  }, [options]);

  // 툴팁 외부 클릭을 감지하기 위한 이벤트 리스너 설정
  useEffect(() => {
    const handleClickOutsideOfTooltip = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node | null) &&
        !tooltipIconRef.current?.contains(event.target as Node | null)
      ) {
        setIsOpenUserCountTooltip(false); // 툴팁 외부를 클릭하면 툴팁을 숨깁니다.
      }
    };

    document.addEventListener("mousedown", handleClickOutsideOfTooltip);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOfTooltip);
    };
  }, []);

  const onClickItem = (index: number) => {
    setSelectedIndex(index);
  };

  if (!options || !articleId) {
    return <></>;
  }

  return (
    <>
      <div className={css.titleFlexWrap}>
        <div className={css.titleFlex}>
          <div className={css.titleUpperFlex}>
            <span className={css.title}>이웃광고</span>
            <span className={css.betaText}>Beta</span>
            <div className={css.tooltipWrap}>
              <div className={css.tooltipOpenIconWrap} ref={tooltipIconRef}>
                <HelpIcon
                  onClick={() => {
                    setIsOpenUserCountTooltip((bool) => !bool);
                  }}
                  fill={vars.$scale.color.gray900}
                />
              </div>
              {isOpenUserCountTooltip && (
                <div className={css.tooltip} ref={tooltipRef}>
                  <div>
                    <span className={css.nowrap}>이웃광고는 물품의 종류,</span>
                    <br />
                    <span className={css.nowrap}>이웃의 관심도 등에 따라</span>
                    <br />
                    <span className={css.nowrap}>가격과 노출수가 달라져요.</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <span className={css.subtitle}>관심 있을 이웃에게 맞춤 홍보해요.</span>
        </div>
        <CloseIcon
          className={css.closeButton}
          onClick={() => {
            pop();
          }}
        />
      </div>
      <div className={css.itemFlexWrap}>
        <div className={css.itemFlex}>
          {options.map((option: OptionType, index: number) => (
            <div
              className={css.item({ isSelected: index === selectedIndex })}
              onClick={() => onClickItem(index)}
              key={option.id}
            >
              <div className={css.flexColumn}>
                <div className={css.flexRow}>
                  <span className={css.itemTitle}>{option.title}</span>
                  {option.recommended && <span className={css.recommandIcon}>추천</span>}
                </div>
                {index === selectedIndex && option.description && (
                  <span className={css.itemSubtitle}>{option.description}</span>
                )}
              </div>
              <div className={css.flexColumn}>
                <div>
                  {option.discount_rate && (
                    <span className={css.itemDiscountRate}>
                      {Math.round(option.discount_rate * 100)}%
                    </span>
                  )}
                  <span className={css.itemPrice}>{option.price.toLocaleString()}원</span>
                </div>
                {option.original_price && (
                  <div className={css.itemSubPrice}>{option.original_price.toLocaleString()}원</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Payment
        articleId={articleId}
        price={options[selectedIndex].price}
        selectedOptionId={options[selectedIndex].id}
      />
    </>
  );
};

export { OptionSheet };
