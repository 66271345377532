import React, { cloneElement, type ReactElement } from "react";

export type ErrorBoundaryProps = {
  renderFallback: ReactElement;
  resetQuery?: () => void;
  children: React.ReactNode;
};

export type States = {
  error?: Error | null;
};

export type RejectedFallbackProps = States &
  Pick<ErrorBoundaryProps, "resetQuery"> & {
    description?: string;
    reset?: boolean;
  };

const initialState = { error: null };

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, States> {
  constructor(props: any) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    console.error(error.message);
  }

  resetQuery = () => {
    this.props.resetQuery?.();
    this.setState(initialState);
  };

  render() {
    const { renderFallback } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return cloneElement(renderFallback, {
        error,
        resetQuery: this.resetQuery,
      });
    }

    return this.props.children;
  }
}
