import { useQuery } from "@tanstack/react-query";
import { getAdOptions } from "../api/advertisement";
import { useSetRecoilState } from "recoil";
import { optionsResState } from "../store/apiResponseStore";

export const useAdOptions = ({
  articleId,
  enabled = true,
}: { articleId: number; enabled?: boolean }) => {
  const setOptions = useSetRecoilState(optionsResState);

  return useQuery({
    queryKey: ["advertisement", "options", articleId],
    queryFn: async () => {
      const optionsRes = await getAdOptions({ articleId });

      const options = optionsRes?.neighborhood_advertisement_options || null;
      setOptions(options);

      return optionsRes;
    },
    staleTime: 0,
    enabled,
  });
};
