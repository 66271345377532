import { useQuery } from "@tanstack/react-query";
import { getAdInfo } from "../api/advertisement";

export const useAdInfo = ({ advertisementId }: { advertisementId: string }) => {
  return useQuery({
    queryKey: ["advertisement", advertisementId],
    queryFn: () => getAdInfo({ advertisementId }),
    staleTime: 0,
  });
};
