import { requestPaymentWebview, type Stage } from "@karrotpay/payment-sdk";
import { AxiosError } from "axios";

import { APP_ENV, PARTNER_APP_SECRET } from "../constants";
import { PayWebviewError } from "./PayWebviewError";
import { bridge } from "../bridge";

type PropsType = { payId: string };

type PaymentWebviewReturnType = { status: "SUCCESS" | "CLOSED" };

export const openKarrotPayWebview = ({ payId }: PropsType): Promise<PaymentWebviewReturnType> =>
  new Promise<PaymentWebviewReturnType>((resolve, reject) => {
    requestPaymentWebview({
      bridge,
      stage: APP_ENV as Stage,
      partnerAppSecret: PARTNER_APP_SECRET,
      payId,
      viewType: "FULL_VIEW",
      onSuccess() {
        resolve({
          status: "SUCCESS",
        });
      },
      onFailure(data) {
        reject(new PayWebviewError(data.message, data.code));
      },
      onClose() {
        resolve({
          status: "CLOSED",
        });
      },
    });
  });
