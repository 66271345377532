import axios, { type AxiosInstance } from "axios";
import { BRIDGE_INFO } from "../bridgeInfo";
import { bridge } from "../bridge";
import { IS_PROD } from "../constants";

import { APP_ENV, HOIAN_ALPHA_HOST, HOIAN_PROD_HOST } from "../constants";

const END_POINT = APP_ENV === "production" ? HOIAN_PROD_HOST : HOIAN_ALPHA_HOST;

const axiosConfig = {
  baseURL: `${END_POINT}/api/v24`,
  headers: {},
};

const hoianApi: AxiosInstance = axios.create(axiosConfig);

hoianApi.interceptors.request.use(async (config) => {
  if (!config.headers) {
    return config;
  }

  config.headers["X-AUTH-TOKEN"] = BRIDGE_INFO.AUTH_TOKEN;
  config.headers["X-USER-AGENT"] = BRIDGE_INFO.USER_AGENT;
  config.headers["X-DEVICE-IDENTITY"] = BRIDGE_INFO.DEVICE_IDENTITY;
  config.headers["ACCEPT-LANGUAGE"] = BRIDGE_INFO.ACCEPT_LANGUAGE;

  return config;
});

const infraMaintenanceRedirectMiddleware = (error: any) => {
  if (error?.response?.status === 599) {
    const errorText =
      typeof error?.response?.data?.status?.message === "string"
        ? error?.response?.data?.status?.message
        : "서비스 점검 중입니다.";

    const APP_SCHEME = `karrot${
      IS_PROD ? "" : ".alpha"
    }://minikarrot/router?remote=https%3A%2F%2Fflea-fallback${
      IS_PROD ? "" : ".alpha"
    }.karrotwebview.com%2Fpages%2Fmaintenance%2Findex.html%23%2F%3Fdescription%3D${encodeURIComponent(
      errorText,
    )}&navbar=true&scrollable=false`;

    window.location.href = APP_SCHEME;
    bridge.closeRouter({});
  }

  // 오류를 다시 던져 다음 catch 블록에서 처리할 수 있게 합니다.
  return Promise.reject(error);
};

hoianApi.interceptors.response.use((res) => res, infraMaintenanceRedirectMiddleware);

export { hoianApi };
