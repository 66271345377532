import { useQuery } from "@tanstack/react-query";
import type { ServerErrorResponseType } from "../lib/ErrorHandler";
import { getAdInfo } from "../api/advertisement";
import { approvePayment } from "../api/payment";
import { nativeAdHandler } from "../lib/nativeAdHandler";
import { useActivityParams } from "@stackflow/react";
import { serverErrorHandler } from "../lib/ErrorHandler";
import { useBridge } from "../bridge";
import { Advertisement } from "../api/types";
import { IS_PROD } from "../constants";
import { getCurrentEnvironment } from "@daangn/karrotbridge/lib/core/getCurrentEnvironment";
import { BRIDGE_INFO } from "../bridgeInfo";

type PropsType = {
  articleId: string;
  payId?: string;
  isFetchEnabled: boolean;
  isFromNativeApp: boolean;
  onError?: (error: any) => void;
};

export const useApprovePayment = ({
  articleId,
  payId,
  isFetchEnabled,
  isFromNativeApp,
  onError,
}: PropsType) => {
  const bridge = useBridge();
  const { from } = useActivityParams();

  return useQuery({
    queryKey: ["article", "approvementPayment", articleId, payId],
    queryFn: async () => {
      if (payId === undefined) {
        throw new Error("payId 부재");
      }

      const approvePaymentResult = await approvePayment({ articleId, payId });

      if (!approvePaymentResult?.neighborhood_advertisement?.id) {
        serverErrorHandler(approvePaymentResult as unknown as ServerErrorResponseType);
      }

      const result = await getAdInfo({
        advertisementId: String(approvePaymentResult.neighborhood_advertisement.id),
      });

      return result;
    },
    onSuccess: async (data: Advertisement) => {
      const advertisementId = String(data.id);

      nativeAdHandler({ type: "COMPLETED", payload: { advertisementId } });

      if (!isFromNativeApp) {
        /**
         * 효과페이지 새 웹뷰로 띄워주는 이유
         * 1. 아이폰에서 효과페이지로 replace해도, 뒤로가기 하면 뒷 스택이 남아있는 현상이 있었어요.
         * 2. 안드로이드에서는 24.7.0 버전부터 COMPLETED 이벤트를 쏘면, 네이티브 앱에서 이웃광고 웹뷰를 자동으로 닫아주게 되어있어요.
         * 위 두 이슈를 해결하기 위해 결제 완료시, 새 웹뷰로 효과페이지를 띄워주고, 결제 이전의 이웃광고 웹뷰는 닫아줘요.
         */
        const impressionPageAppScheme =
          `karrot${IS_PROD ? "" : ".alpha"}://minikarrot` +
          `/router?navbar=false&present=top&` +
          `remote=` +
          encodeURIComponent(
            `https://smartads${
              IS_PROD ? "" : ".alpha"
            }.karrotwebview.com/neighborhood_advertisements/${advertisementId}/impression`,
          ) +
          encodeURIComponent(
            `?is_open_option_sheet=false&from=${from}&referrer=neighborhood_ad_payment_success`,
          );

        if (getCurrentEnvironment() === "Android") {
          // 안드로이드는 "결제완료되었어요" 다이얼로그를 웹뷰가 띄워져있을때 노출해주기 때문에, 500ms정도 노출될 텀을 줘요.
          setTimeout(() => {
            window.location.href = impressionPageAppScheme;

            /**
             * 안드로이드에선 24.07버전부터 COMPLETE이벤트를 받을시, 웹뷰를 닫는 동작이 네이티브 앱 코드에 있어요.
             * 이때 웹뷰에서도 닫기 코드를 실행하면, 결제이후 판매내역이 새로고침 되지 않는 버그가 있었어요.
             */
            if (
              !isNaN(Number(BRIDGE_INFO.APP_VERSION_CODE)) &&
              Number(BRIDGE_INFO.APP_VERSION_CODE) < 240700
            ) {
              bridge.closeRouter({});
            }
          }, 500);
        } else {
          window.location.href = impressionPageAppScheme;
          bridge.closeRouter({});
        }
      }
    },
    onError,
    enabled: isFetchEnabled,
    retry: false,
    suspense: false,
    useErrorBoundary: false,
    staleTime: 0,
    cacheTime: 0,
  });
};
