import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import React, { useCallback, Suspense, type ComponentProps } from "react";

import ErrorBoundary from "./ErrorBoundary";

type ErrorBoundaryProps = ComponentProps<typeof ErrorBoundary>;

interface Props extends Omit<ErrorBoundaryProps, "renderFallback"> {
  pendingFallback: ComponentProps<typeof Suspense>["fallback"];
  rejectedFallback: ErrorBoundaryProps["renderFallback"];
}

function AsyncBoundary({
  pendingFallback,
  rejectedFallback,
  children,
  ...errorBoundaryProps
}: Props) {
  const { reset } = useQueryErrorResetBoundary();
  const resetHandler = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <ErrorBoundary
      renderFallback={rejectedFallback}
      resetQuery={resetHandler}
      {...errorBoundaryProps}
    >
      <React.Suspense fallback={pendingFallback}>{children}</React.Suspense>
    </ErrorBoundary>
  );
}

export default AsyncBoundary;
