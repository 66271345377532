import * as React from "react";
import { Spinner } from "@daangn/sprout-components-spinner";

import * as css from "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className={css.dimBackground}>
      <Spinner size="medium" variant="gray" />
    </div>
  );
};

export default LoadingSpinner;
