import { BottomSheet } from "@stackflow/plugin-basic-ui";
import { type ActivityComponentType } from "@stackflow/react";
import { vars } from "@seed-design/design-token";
import { OptionSheet } from "../components/OptionSheet";
import { useEffect } from "react";
import { logAnalyticsEvent } from "../bridge";

type BottomSheetParams = {
  articleId: string;
  from?: string;
};

const OptionBottomSheet: ActivityComponentType<BottomSheetParams> = ({ params }) => {
  useEffect(() => {
    logAnalyticsEvent(
      "KARROT",
      "client_shown_fleamarket_neighborhoodAdvertisement_optionSelectionBottomSheet_v1",
      {
        article_id: String(params.articleId),
        previous_page: String(params.from),
        option_view_type: "select_option",
      },
    );
  }, []);

  return (
    <BottomSheet dimBackgroundColor={vars.$semantic.color.overlayDim}>
      <OptionSheet articleId={params.articleId} />
    </BottomSheet>
  );
};

export default OptionBottomSheet;
